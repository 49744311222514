import React from 'react';
import { CampaignsPageBody } from '../components/CampaignsPageBody';
import {
  Campaign,
  CampaignStatus,
  campaignStatusTitle,
  getFlatSpendAmount,
  useCampaigns
} from '../service';
import { Loader } from '../../../components/Loader';
import { groupBy } from 'lodash';
import { styled } from '../../../emotion';
import Typography from '@material-ui/core/Typography';
import { FlexContainer } from '../../../layout/Flex';
import { useSpaceCurrency } from '../../../services/useSpaceCurrency';
import { CampaignCard } from '../components/CampaignCard';
import { Helmet } from 'react-helmet';
import { formatChartCurrency } from '../../../components/Charts/Util';
import { CampaignStatusIcon } from '../components/CampaignStatusIcon';
import { useTrackMixpanelView } from '../../../services/mixpanel';
import { SearchInput } from '../../../components/SearchInput';
import { useStringQueryParam } from '../../../routes';
import { useCurrentUserScopes } from '../../../services/currentUser';
import { NoPermissions } from '../../../components/NoPermissions';

const BoardGridWrapper = styled('div')`
  margin: ${(p) => p.theme.spacing(3)}px auto;
  max-width: 1200px;
`;

const BoardGrid = styled('div')`
  padding-top: ${(t) => t.theme.spacing(2)}px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${(t) => t.theme.spacing(2)}px;
  column-gap: ${(t) => t.theme.spacing(4)}px;
`;

const boardCampaignStatuses: CampaignStatus[] = [
  'planning',
  'scheduled',
  'running'
];

const Count = styled('span')`
  font-size: 12px;
  font-weight: bold;
  color: ${(t) => t.theme.palette.grey[800]};
  background-color: ${(t) => t.theme.palette.grey[300]};
  border-radius: 4px;
  padding: 2px 11px;
  font-family: monospace;
`;

const matchesSearch = (c: Campaign, search: string) => {
  if (!search) return true;
  return (
    c.name.toLowerCase().includes(search) ||
    c.advertisers.some((a) => a.name.toLowerCase().includes(search))
  );
};

const Board: React.FC<{ campaigns: Campaign[] }> = ({ campaigns }) => {
  const byStatus = groupBy(campaigns, 'status');
  const currency = useSpaceCurrency();
  return (
    <BoardGrid>
      {boardCampaignStatuses.map((status) => {
        const campaigns = byStatus[status] || [];
        const spend = campaigns.reduce(
          (acc, c) => acc + getFlatSpendAmount(c),
          0
        );
        return (
          <FlexContainer
            key={status}
            alignItems="center"
            justifyContent="space-between"
          >
            <FlexContainer alignItems="center">
              <CampaignStatusIcon status={status} />
              <Typography variant="body1">
                {campaignStatusTitle(status)}
              </Typography>
              <Count>{campaigns.length}</Count>
            </FlexContainer>
            <Typography variant="caption" color="textSecondary">
              {formatChartCurrency(spend, currency, { excludeCents: true })}
            </Typography>
          </FlexContainer>
        );
      })}
      {boardCampaignStatuses.map((status) => {
        const campaigns = byStatus[status] || [];
        return (
          <FlexContainer key={status} direction="column" spacing={2}>
            {campaigns.map((campaign) => (
              <CampaignCard key={campaign.id} campaign={campaign} />
            ))}
          </FlexContainer>
        );
      })}
    </BoardGrid>
  );
};

export const CampaignsBoard: React.FC = () => {
  const scopes = useCurrentUserScopes();
  const canView = scopes.has('campaigns.view');
  const [campaigns, loading] = useCampaigns();
  const [search, setSearch] = useStringQueryParam('q');
  useTrackMixpanelView('view_campaigns_overview');

  return (
    <>
      <Helmet>
        <title>Campaigns | Affilimate</title>
      </Helmet>
      <CampaignsPageBody>
        {canView ? (
          <BoardGridWrapper>
            <FlexContainer alignItems="center">
              <Typography component="h1" variant="h6">
                <strong>Active campaigns</strong>
              </Typography>
              <SearchInput
                value={search}
                onChange={setSearch}
                placeholder="Search campaigns"
                width={300}
              />
            </FlexContainer>
            {loading || !campaigns ? (
              <Loader size={36} height={500} />
            ) : (
              <Board
                campaigns={campaigns.filter((c) => matchesSearch(c, search))}
              />
            )}
          </BoardGridWrapper>
        ) : (
          <NoPermissions />
        )}
      </CampaignsPageBody>
    </>
  );
};
